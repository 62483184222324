<template>
    <div id="Recipes" class="m-0 p-0 row">

        <div class="col-12 d-flex flex-wrap p-0 m-0 justify-content-start justify-content-xl-end align-items-end py-2 py-xl-4">

            <div class="col-4 col-xxl-3 position-relative">
                <input @input="filterElements"
                    class="custom_input opensans-bold text-dark pl-3 w-100"
                    type="text"
                    v-model="search_query"
                    placeholder="Buscar"
                    />
                <img class="position-absolute"
                    style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                    :src="lupa_icon_b" />
            </div>
            <div class="col-4 col-xxl-3">
                <ns-select @change="filterElements"
                    theme="light"
                    class="opensans-bold text-dark"
                    label="Filtrar por:"
                    variant="white"
                    :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                    v-model="filter"
                    full
                    rounded>
                </ns-select>
            </div>
            <div class="col-4 col-xxl-3 px-2 px-xl-5">
                <button @click.stop="recipes_modal = true;"
                    class="shadow_btn py-2 mr-3 w-100"
                    type="button">
                    Agregar Categoría
                </button>
            </div>
        </div>

        <div class="col-12" v-if="loading">
            <ns-spinner size="sm" variant="success"/>
        </div>

        <div class="col-12" v-if="empty_search && !search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con el filtro seleccionado.
            </p>
        </div>

        <div class="col-12" v-if="empty_search && search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con esos parámetros de búsqueda.
            </p>
        </div>

        <div class="col-12" v-if="!loading && !fetch_error && !empty_search && categories.length > 0">
            <ns-table :data="recipes_data" 
                :allow_headers="recipes_headers"
                color_headers="gray" color_headers_text="black" pagination_off
                color_body="white" border_body="white" color_body_text="black">
                <template v-slot:active="data">
                    <span>
                        {{ data.item.active ? 'Activo' : 'Inactivo'}}
                    </span>
                </template>
                <template v-slot:actions="data">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-12 position-relative">
                            <button @click.stop="data.item.open = true"
                                class="w-100"
                                style="border: none; background: none;"
                                type="button">
                                Ver
                            </button>

                            <div @mouseleave="data.item.open = false"
                                class="position-absolute d-flex flex-column bg-white" 
                                style="width: 200px; height: 100px; z-index: 15; top: -5vh; right: 0vw;" v-if="data.item.open">
                                <button @click.stop="selectTableItem('edit', data.item.id, data.item)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Editar
                                </button>

                                <button @click.stop="selectTableItem('delete', data.item.id)" v-if="data.item.active"
                                    class="bg-grey mx-3 my-3"
                                    style="border: none;"
                                    type="button">
                                    Borrar
                                </button>

                                <button @click.stop="selectTableItem('activate', data.item.id)" v-if="!data.item.active"
                                    class="bg-grey mx-3 my-3"
                                    style="border: none;"
                                    type="button">
                                    Activar
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </template>
            </ns-table>
        </div>

        <!---- Paginator ----->

        <div class="col-12 py-3" v-if="!fetch_error && !empty_search">
            <div class="row justify-content-evenly align-items-end">

                <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start">
                    <span class="mr-3 opensans-bold text-dark">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 50px;"
                        v-model="rows"
                        class="custom_input text-center"
                        :disabled="page > 1"
                        />
                    
                    <ns-select @change="filterElements"
                        theme="light"
                        class="opensans-bold text-dark ml-2"
                        style="width: 180px;"
                        variant="white"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc"
                        full>
                    </ns-select>
                </div>



                <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-end pr-0 pr-xl-4 py-3 py-lg-0">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="custom_input text-center mx-4"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!----------- Modales  ------------->
        <ns-modal :show_modal="recipes_modal" bg_color="white" max_width="680"
            overlay_close @onClose="recipes_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <!-- <div class="col-12 col-md-6 pl-4 pr-4">
                    <ns-select label="Comida"
                        theme="light"
                        :options="[{id: 0, label: 'Seleccione'},{id: 1, label: 'Desayuno'},{id: 2, label: 'Comida'}, {id: 3, label: 'Cena'}, {id: 4, label: 'Bebidas'}, {id: 5, label: 'Postres'}]"
                        v-model="food_id"
                        variant="white"
                        rounded
                        full>
                    </ns-select>
                </div> -->

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-input label="Categoría:" 
                        theme="light"
                        v-model="name"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                </div>

                <!-- <div class="col-12 col-md-6 pr-4 pl-4 my-3">
                    <ns-select label="Estatus"
                        theme="light"
                        :options="[{id: 0, label: 'Seleccione'}, {id: 1, label: 'Activo'}, {id: 2, label: 'Inactivo'}]"
                        v-model="status"
                        variant="white"
                        rounded
                        full>
                    </ns-select>
                </div> -->

                <div class="col-6 mt-3 montserrat-bold">
                    <div class="row align-items-center h-100">
                        <div class="col-12 d-flex justify-content-between">
                            <button @click.stop="createEditElement"
                                class="btn btn-success"
                                style="border-radius: 15px;"
                                type="button">
                                Guardar
                            </button>
                            <button @click.stop="clearFields"
                                class="btn btn-danger"
                                style="border-radius: 15px;"
                                type="button">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ns-modal>

        <ns-modal :show_modal="delete_modal" bg_color="white" max_width="680"
            overlay_close @onClose="delete_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de eliminar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="inactivateElement"
                        class="btn btn-danger btn-pill"
                        type="button">
                        Eliminar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>

        <ns-modal :show_modal="active_modal" bg_color="white" max_width="680"
            overlay_close @onClose="active_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de activar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="activateElement"
                        class="btn btn-success btn-pill"
                        type="button">
                        Activar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>
    </div>
</template>

<script>
    import lupa_icon_b from 'ASSETS/icons/lupa_icon_b.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon_b,
                arrow_left,
                recipes_modal: false,
                delete_modal: false,
                active_modal: false,
                search_query: null,
                loading: true,
                fetch_error: false,
                empty_search: false,
                selected_id: null,
                recipes_headers: [
                    // {key: 'title', label: 'Título'},
                    // {key: 'type', label: 'Tipo'},
                    // {key: 'date', label: 'Fecha'},
                    {key: 'name', label: 'Receta'},
                    {key: 'active', label: 'Estatus'},
                    {key: 'actions', label: 'Acciones'}
                ],
                recipes_data: [],
                name: null,
                // food_id: '0',
                // title: null,
                // status: '0',
                filter: 'all',
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2',
            }
        },
        methods: {
            //helpers.
            clearFields() {
                // this.food_id = '0'
                // this.title = null
                // this.status = null

                this.name = null
                this.selected_id = null

                this.recipes_modal = false;
                this.delete_modal = false;
                this.active_modal = false
            },
            async selectTableItem(type, id, data){
                //selecciona el id de un item, y despliega el modal necesario
                switch(type) {
                    case 'edit':
                        if(!data.active) {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'No se permite la edición de elementos inactivos',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }
                        this.selected_id = id
                        await this.$store.dispatch('recipe_categories/view', { id_recipe_category: this.selected_id})
                        this.name = this.category.name
                        this.recipes_modal = true
                        break;
                    case 'delete':
                        this.selected_id = id
                        this.delete_modal = true
                        break;
                    case 'activate':
                        this.selected_id = id
                        this.active_modal = true
                        break;
                    default:
                        console.info('Invalid case')
                        return
                }
            },
            async filterElements(){
                let search = ''
                this.loading = true
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('recipe_categories/list', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('recipe_categories/list', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('recipe_categories/list', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.categories === null) {
                    this.empty_search = true
                    this.loading = false
                    return
                }

                else {
                    this.empty_search = false
                    this.loading = false
                }
                
               this.recipes_data = this.categories.map( category => {
                    return {
                        ...category,
                        actions: '-',
                        open: false
                    }
                })

                
            },
            // aliment CRUD
            async createEditElement() {
                for(let error in this.errors) {
                    this.errors[error] = null
                }

                let complete = true

                if(_.isNull(this.name) || _.isEmpty(this.name)) {
                    this.errors.name = '*Campo requerido'
                    complete = false
                }


                if(complete) {

                    if(this.selected_id) {
                        let payload = {
                            body: {
                                name: this.name,
                            },
                            query: {
                                id_recipe_category: this.selected_id
                            }
                        }

                        await this.$store.dispatch('recipe_categories/update', payload)
                        this.clearFields()
                        this.filterElements()
                    }

                    else {
                        let payload = {
                            name: this.name,
                        }
                        
                        await this.$store.dispatch('recipe_categories/add', payload)
                        this.clearFields()
                        this.filterElements()
                    }
                }
            },
            async inactivateElement() {
                await this.$store.dispatch('recipe_categories/delete', { id_recipe_category: this.selected_id, active: false})
                this.clearFields()
                this.filterElements()
            },

            async activateElement() {
                await this.$store.dispatch('recipe_categories/delete', { id_recipe_category: this.selected_id, active: true})
                this.clearFields()
                this.filterElements()
            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                this.page++;
                this.filterElements()
            }
        },
        computed: {
            ...mapGetters({
                category: 'recipe_categories/getCategory',
                categories: 'recipe_categories/getCategories',
                last_page: 'recipe_categories/getLastPage',
                total_pages: 'recipe_categories/getTotalPages'
            })
        },
        async created() {
            try {
                //sets title for the view
                this.$store.commit('setTitle', 'CATEGORÍA RECETAS')
                this.$store.commit('setIndex', 0)

                //list of recipes categories
                await this.$store.dispatch('recipe_categories/list', {datatable: true, page: this.page, rows: this.rows, order_asc: false, active: true})

                if(this.categories) {
                    this.recipes_data = this.categories.map( category => {
                        return {
                            ...category,
                            actions: '-',
                            open: false
                        }
                    })
                }
                

                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                console.error(error)
                this.loading = false
                this.fetch_error = true
            }
        }
    }
</script>

<style scoped>
    .custom_position {
        right: 10;
        
    }

    .custom_input {
        background-color: #e9ecef;
        border: none;
        height: 40px;
        border-radius: 10px;
    }

    @media screen and (max-width: 928px){
            .custom_position {
                right: 0;
            }
        }
</style>